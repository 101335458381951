import React, { useEffect } from 'react'
import LandingHeader from './QuantraHeader';
import Footer from './Footer';
import { CarouselCard } from '../Comoponent/CarouselCard';
import { QProject } from '../Comoponent/QProject';
import { About } from '../Comoponent/About';
import Projects from '../components/Projects/Projects';
import { BookAppointment } from '../Comoponent/BookAppointment';
import { FooterCard } from '../Comoponent/FooterCard';
import { CarouselCard2 } from '../Comoponent/CarouselCard2';
import { UspsPage } from '../WebScreen/Usps';

export const QuantraLanding = () => {
    const scrollUp = () => {
        window.scroll({
            top: 0,
            behavior: "smooth",
        });
    };
    useEffect(() => {
        scrollUp()
    }, [])
    return (
        <div>
            <div className='col-12 d-block d-lg-none' >
                <LandingHeader />
            </div>
            <div className='col-12 d-none d-lg-block'>
                <LandingHeader />
            </div>
            <br />
            <div className='mt-2' >
                <About />
            </div>
            <UspsPage />

            <div className='d-none d-md-block' >
                <CarouselCard />
            </div>

            <div className='d-block d-md-none' style={{ padding: '4px' }} >
                <CarouselCard2 />
            </div>

            <div className='mt-5' >
                <QProject />
            </div>
            {/* {/* <div>
                <Projects />
            </div> */}
            {/* <br />  */}
            <div>
                <FooterCard />
            </div>
            <div>
                <Footer />
            </div>
        </div>
    )
} 