import { Typography, p } from "@mui/material";
import React, { useState } from "react";
import { Carousel } from "react-bootstrap";

import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import axios from 'axios'
import ReCAPTCHA from "react-google-recaptcha";
import Swal from 'sweetalert2';
import { FaPhone, FaWhatsapp } from 'react-icons/fa'
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import SimpleBackdrop from "../BackDrop/BackDrop";

const LandingHeader = () => {

    const [name, setName] = useState('')
    const [city, setMobile] = useState('')
    const [email, setEmail] = useState('')
    const [country, setCountry] = useState('')
    const [message, setMessage] = useState('White')

    // const contaSuccessfullAlert = () => {
    //     Swal.fire({
    //         icon: 'success',
    //         title: "Successful !",
    //         confirmButtonColor: "rgb(126, 110, 228)",
    //         cancelButtonColor: "#d33",
    //         showCancelButton: false,
    //         confirmButtonText: "Ok"
    //     }).then((result) => {
    //         if (result.value) {
    //             window.open('https://quantra.in/thank-you/', '_blank');
    //             return false;
    //         } else {
    //             window.open('https://quantra.in/thank-you/', '_blank');
    //             return false;
    //         }
    //     })
    // }

    const [isSubmiting, setIsSubmiting] = useState(false)

    const contact = () => {
        setIsSubmiting(true)
        axios.post('https://quantraapi.emedha.in/staffs/add-contact', {
            name,
            email,
            message,
            country,
            city
        }).then((res) => {
            if (res.data.status === 200) {
                setIsSubmiting(false)
                emailSend(name, email, message, country, city)
                window.open('https://quantra.in/thank-you/', '_blank');
            }
        })
    }

    const [Validated, setValidated] = useState(false);

    const handleSubmitGst = (event) => {
        event.preventDefault()
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            isNotValid ? setIsNotValid(true) : contact()
        }

        isNotValid ? setIsNotValid(true) : setIsNotValid(false)

        setValidated(true);
    };

    const [open, setOpen] = React.useState(false);


    const handleClose = () => {
        setOpen(false);
    };

    const emailSend = (name, email, message, country, city) => {
        axios.post('https://quantraapi.emedha.in/staffs/send-email', {
            name, email, message, country, mobile: city
        }).then((res) => {
            if (res.data.status === 200) {
                return
            }
        })
    }

    const [isNotValid, setIsNotValid] = useState(false)

    const mobileValidation = (e) => {
        console.log(e.target.value.length === 10)
        if (e.target.value.length !== 10) {
            setIsNotValid(true)
        } else {
            setMobile(e.target.value)
            setIsNotValid(false)
            return
        }

    }


    return (
        <center >

            <div style={{ height: '10.5rem', width: '3rem', backgroundColor: 'white', position: 'fixed', bottom: 0, right: 5, zIndex: 1000 }}  >

                <a href="mailto:digital@quantra.in" target="_blank" >
                    <div style={{ width: '4rem', backgroundColor: 'rgb(150, 59, 59)', height: '3.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingRight: 9.1 }} >
                        <MailOutlineIcon style={{ color: 'white', fontSize: 24.5 }} />
                    </div>
                </a>

                <a href="tel:8556800800" >
                    <div style={{ width: '4rem', backgroundColor: 'rgb(10, 71, 224)', height: '3.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingRight: 9.1 }} >
                        <FaPhone style={{ fontSize: 24.5, color: 'white' }} />
                    </div>
                </a>

                <a href="https://api.whatsapp.com/send?phone=8556800800" target="_blank" >
                    <div style={{ width: '4rem', backgroundColor: 'green', height: '3.5rem', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingRight: 9.1 }} >
                        <FaWhatsapp style={{ color: 'white', fontSize: 24.5 }} />
                    </div>
                </a>

            </div>

            <div className='card nav-container' style={{ width: '100%', height: '6rem', background: 'white', borderRadius: '0.0003rem', border: 'none', justifyContent: 'center', position: 'fixed', top: '0rem', zIndex: 1000000 }} >
                <div className='container-fluid card' style={{ borderRadius: '0.0rem', border: 'none', backgroundColor: 'white', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', width: '100%' }} >
                    <div style={{ width: '45%', border: '0.7px solid lightgray', position: 'relative', top: '0.3rem', }} ></div>
                    <div style={{ position: 'relative', top: '0.3rem', }} >
                        <p className='v-con-logo typo' style={{ color: 'white', fontSize: '1.4rem', fontWeight: '800', }} ><img src={require('../../assets/qlogo.png')} style={{ width: '150px', borderRadius: '0.1rem', marginRight: 3, position: 'relative', top: '0.2rem' }} /></p>
                    </div>
                    <div style={{ width: '45%', border: '0.7px solid lightgray', position: 'relative', top: '0.3rem', }} ></div>
                </div>
            </div>

            <div className="justify-content-around d-block d-lg-flex" style={{ backgroundColor: 'transparent', width: '100%' }} >

                <div className="justify-content-center align-items-md-center d-none d-md-flex" style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, width: '100%', height: '100vh', display: 'flex', zIndex: 20 }} >

                    <div style={{ width: '70%' }} >
                        <Typography className="typo" style={{ marginTop: '-8rem', color: 'white', fontSize: 29, fontWeight: '900', position: 'relative', top: '9rem', left: '-10rem', textAlign: 'start' }} >From slabs to vanities, Quantra takes quartz  <br /> to the next level</Typography>
                    </div>
                </div>

                <div className="justify-content-center align-items-md-center d-block d-md-none" style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, width: '100%', height: '100vh', display: 'flex', zIndex: 20 }} >

                    <div style={{ width: '100%' }} >
                        <Typography className="typo text-center" style={{ marginTop: 44, color: 'white', fontSize: 16, fontWeight: '900', position: 'relative', top: '9rem', textAlign: 'start' }} >From slabs to vanities, Quantra takes quartz to the next level</Typography>
                    </div>
                </div>
            </div>

            <div className="col d-block d-md-none" >
                <Carousel controls={false} indicators={false} fade style={{ width: '100%', borderRadius: '0.0003rem', position: 'relative' }} >
                    <div className="col d-block d-md-none " style={{ marginTop: '5.9rem' }} >
                        <video width="100%" autoPlay muted loop>
                            <source style={{ height: '1004px' }} src={require('../../assets/video.mp4')} type="video/ogg" />
                        </video>
                    </div>
                </Carousel>
            </div>
            <div className="col d-none d-md-block" >
                <Carousel controls={false} indicators={false} fade style={{ borderRadius: '0.0003rem', position: 'relative' }} >
                    <div className="col d-none d-md-block " style={{ marginTop: '-2rem' }} >
                        <video width="100%" height='80%' autoPlay muted loop>
                            <source style={{ height: '1004px' }} src={require('../../assets/video.mp4')} type="video/ogg" />
                        </video>
                        <div className='col-lg-5 shadow' style={{ backgroundColor: 'white', padding: 32, position: 'absolute', right: 56, bottom: '1.2rem', zIndex: 99, width: '29.3%' }}  >
                            <Form noValidate validated={Validated} onSubmit={handleSubmitGst} >
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="12" controlId="validationCustom01">

                                        <Form.Control
                                            required
                                            value={name}
                                            type="text"
                                            placeholder="Full Name*"
                                            style={{ padding: '0.8rem', borderRadius: '0.1rem', marginTop: 23 }}
                                            onChange={(e) => setName(e.target.value)}
                                            maxLength={20}
                                            minLength={3}
                                        />

                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid Name
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" controlId="validationCustom01">

                                        <Form.Control
                                            required

                                            type="email"
                                            placeholder="Email*"
                                            style={{ padding: '0.8rem', borderRadius: '0.1rem', marginTop: 23 }}
                                            onChange={(e) => setEmail(e.target.value)}


                                        />

                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid email
                                        </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group as={Col} md="12" controlId="validationCustom01">

                                        <Form.Control
                                            required
                                            type='number'

                                            placeholder="Mobile*"
                                            style={{ padding: '0.8rem', borderRadius: '0.1rem', marginTop: 23 }}
                                            onChange={mobileValidation}

                                        />

                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid mobile
                                        </Form.Control.Feedback>

                                        {
                                            isNotValid && (
                                                <Typography style={{ color: 'red', fontSize: '0.9rem' }} >
                                                    Mobile Number should be 10 digits
                                                </Typography>
                                            )
                                        }

                                    </Form.Group>
                                    <Form.Group as={Col} md="12" controlId="validationCustom01">

                                        <select
                                            required
                                            type="text"

                                            placeholder="Message *"
                                            style={{ padding: '0.8rem', borderRadius: '0.1rem', marginBottom: 5, width: '100%', border: '1px solid lightgray', marginTop: 23 }}
                                            onChange={(e) => setMessage(e.target.value)}
                                        >
                                            <option selected value="" disabled >Requirement*</option>
                                            <option>White</option>
                                            <option>Beige</option>
                                            <option>Grey</option>
                                        </select>

                                        <Form.Control.Feedback type="invalid">
                                            Requirement can't be empty
                                        </Form.Control.Feedback>

                                    </Form.Group>
                                </Row>
                                <div style={{ width: '100%' }} >
                                    <ReCAPTCHA
                                        sitekey="6Lf8CtgnAAAAABL5f01ssnLvpVAFyLd4cp0ZX9t9"
                                    />
                                </div>
                                <button style={{ width: '100%', marginTop: 23 }} className='car-button' >Submit</button>
                            </Form>
                        </div>
                    </div>
                </Carousel>
            </div>

            <div className='col-12 col-md-11 col-lg-6 col-xl-3 d-block d-md-none' style={{ marginTop: 0, position: 'relative', zIndex: 100, marginBottom: 140 }} >
                <div className='col-12 col-md-8 col-lg-5 col-xl-3 shadow' style={{ backgroundColor: 'white', padding: 32, }}  >
                    <Form noValidate validated={Validated} onSubmit={handleSubmitGst} >
                        <Row className="mb-3">
                            <Form.Group as={Col} md="12" controlId="validationCustom01">

                                <Form.Control
                                    required

                                    type="text"
                                    placeholder="Full Name*"
                                    style={{ padding: '0.8rem', borderRadius: '0.1rem', marginTop: 23 }}
                                    onChange={(e) => setName(e.target.value)}

                                    maxLength={25}
                                    minLength={3}

                                />

                                <Form.Control.Feedback type="invalid">
                                    Please Enter Valid Name
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="12" controlId="validationCustom01">

                                <Form.Control
                                    required

                                    type="email"
                                    placeholder="Email*"
                                    style={{ padding: '0.8rem', borderRadius: '0.1rem', marginTop: 23 }}
                                    onChange={(e) => setEmail(e.target.value)}

                                />

                                <Form.Control.Feedback type="invalid">
                                    Please Enter Valid email
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group as={Col} md="12" controlId="validationCustom01">

                                <Form.Control
                                    required
                                    type="number"
                                    placeholder="Mobile*"
                                    style={{ padding: '0.8rem', borderRadius: '0.1rem', marginTop: 23 }}
                                    onChange={ mobileValidation }

                                />

                                {
                                    isNotValid && (
                                        <Typography style={{ color: 'red', fontSize: '0.9rem' }} >
                                            Mobile Number should be 10 digits
                                        </Typography>
                                    )
                                }

                                <Form.Control.Feedback type="invalid">
                                    Please Enter Valid mobile
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md="12" controlId="validationCustom01">

                                <select
                                    required
                                    type="text"

                                    placeholder="Message *"
                                    style={{ padding: '0.8rem', borderRadius: '0.1rem', marginBottom: 5, width: '100%', border: '1px solid lightgray', marginTop:23 }}
                                    onChange={(e) => setMessage(e.target.value)}
                                >
                                    <option selected value="" disabled >Requirement*</option>
                                    <option>White</option>
                                    <option>Beige</option>
                                    <option>Grey</option>
                                </select>

                                <Form.Control.Feedback type="invalid">
                                    Requirement can't be empty
                                </Form.Control.Feedback>

                            </Form.Group>
                        </Row>
                        <div style={{ width: '100%', marginTop: 23 }} >
                            <ReCAPTCHA
                                sitekey="6Lf8CtgnAAAAABL5f01ssnLvpVAFyLd4cp0ZX9t9"
                            />
                        </div>
                        <button style={{ width: '100%', marginTop: 23 }} className='car-button' >Submit</button>
                    </Form>
                </div>
                <div>

                    <Dialog
                        style={{ zIndex: 100000000 }}
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                    >
                        <DialogTitle id="alert-dialog-title">
                            <p className="typo" style={{ fontSize: '1.2rem', color: 'red' }} >Contact Us</p>
                        </DialogTitle>
                        <DialogContent style={{ width: '23rem' }} >
                            <DialogContentText style={{ fontSize: '0.87rem' }} id="alert-dialog-description">
                                Sy. Nos. 901,902, 908-912, Mekaguda Gram Panchayat, Nandigam Mandal, Ranga Reddy District, Telangana – 509 228, India.
                            </DialogContentText>
                            <br />
                            <Form noValidate validated={Validated} onSubmit={handleSubmitGst} >
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="12" controlId="validationCustom01">

                                        <Form.Control
                                            required

                                            type="text"
                                            placeholder="Full Name*"
                                            style={{ padding: '0.8rem', borderRadius: '0.1rem' }}
                                            onChange={(e) => setName(e.target.value)}
                                            maxLength={25}
                                            minLength={3}

                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid Name
                                        </Form.Control.Feedback>
                                        <br />
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" controlId="validationCustom01">

                                        <Form.Control
                                            required

                                            type="email"
                                            placeholder="Enter your Email*"
                                            style={{ padding: '0.8rem', borderRadius: '0.1rem' }}
                                            onChange={(e) => setEmail(e.target.value)}


                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid Email
                                        </Form.Control.Feedback>
                                        <br />
                                    </Form.Group>
                                    <Form.Group as={Col} md="12" controlId="validationCustom01">

                                        <Form.Control
                                            required
                                            type="number"
                                            placeholder="Mobile*"
                                            style={{ padding: '0.8rem', borderRadius: '0.1rem' }}
                                            onChange={mobileValidation}

                                        />

                                        {
                                            isNotValid && (
                                                <Typography style={{ color: 'red', fontSize: '0.9rem' }} >
                                                    Mobile Number should be 10 digits
                                                </Typography>
                                            )
                                        }
                                        
                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid mobile Number
                                        </Form.Control.Feedback>
                                        <br />
                                    </Form.Group>

                                    <Form.Group as={Col} md="12" controlId="validationCustom01">

                                        <select
                                            required
                                            type="text"

                                            placeholder="Message *"
                                            style={{ padding: '0.8rem', borderRadius: '0.1rem', width: '100%', border: '1px solid lightgray', marginTop: 23 }}
                                            onChange={(e) => setMessage(e.target.value)}
                                        >
                                            <option selected value="" disabled >Requirement*</option>
                                            <option>White</option>
                                            <option>Beige</option>
                                            <option>Grey</option>
                                        </select>

                                        <Form.Control.Feedback type="invalid">
                                            Please Enter Valid message
                                        </Form.Control.Feedback>
                                        <br />
                                    </Form.Group>
                                </Row>
                                <div style={{ width: '100%' }} >
                                    <ReCAPTCHA
                                        sitekey="6Lf8CtgnAAAAABL5f01ssnLvpVAFyLd4cp0ZX9t9"
                                    />
                                </div>
                                <button type="submit" style={{ width: '100%', marginTop: 23 }} className='car-button' >Submit</button>
                            </Form>
                        </DialogContent>

                    </Dialog>
                </div>
            </div>
            {
                isSubmiting && (
                    <SimpleBackdrop></SimpleBackdrop>
                )
            }
        </center >
    );
};

export default LandingHeader;