import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import '../Comoponent/component.css'

import LightGallery from 'lightgallery/react';
// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import 'lightgallery/css/lg-share.css';
import 'lightgallery/css/lg-rotate.css';
import { useNavigate } from "react-router-dom";

// import plugins if you need
import lgThumbnail from 'lightgallery/plugins/thumbnail';
import lgZoom from 'lightgallery/plugins/zoom';
import lgShare from 'lightgallery/plugins/share';
import lgRotate from 'lightgallery/plugins/rotate';
import { Button, Typography } from '@mui/material';
import { Fade } from 'react-awesome-reveal';

function Gallery() {


    const navigate = useNavigate()

    const onBeforeSlide = (detail) => {
        const { index, prevIndex } = detail;
        console.log(index, prevIndex);
    };

    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
         
            <div className='card nav-container' style={{ width: '100%', height: '4.9rem', background: 'white', borderRadius: '0.0003rem', border: 'none', justifyContent: 'center', position: 'fixed', top: '0rem', zIndex: 100 }} >
                <div className='container-fluid card' style={{ borderRadius: '0.0rem', border: 'none', backgroundColor: 'white', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', width: '100%' }} >
                    <div style={{ width: '45%', border: '0.7px solid lightgray' }} ></div>
                    <div onClick={()=>navigate('/')} style={{ position: 'relative', top: '-0.21rem' }} >
                        <Typography className='v-con-logo' style={{cursor:'pointer' ,color: 'white', fontSize: '1.4rem', fontWeight: '800', }} ><img src={require('../../assets/qlogo.png')} style={{ width: '150px', borderRadius: '0.1rem', marginRight: 3, position: 'relative', top: '0.2rem' }} /></Typography>
                    </div>
                    <div style={{ width: '45%', border: '0.7px solid lightgray' }} ></div>
                </div>
            </div>
            <Fade>
                <div className='p-5 pb-2' style={{ marginTop: 60, paddingTop: 3, borderRadius: 5, alignItems: 'center', justifyContent: 'center' }} >
                    <Typography style={{ fontSize: '1.6rem', color: 'red',fontStyle: 'italic' }} >White</Typography>
                    <LightGallery
                        onBeforeSlide={onBeforeSlide}
                        speed={790}
                        plugins={[lgThumbnail, lgZoom, lgShare, lgRotate]}

                    >
                        <a className='galler-image' href='https://quantra.in/wp-content/uploads/2023/01/app-2-14.jpg' >
                            <img src='https://quantra.in/wp-content/uploads/2023/01/app-2-14.jpg' className='mb-2' style={{ width: '33.33333333333333%', borderRadius: 2 }} />
                        </a>

                        <a className='galler-image' href='https://quantra.in/wp-content/uploads/2023/01/9962_desktopp_application_1.jpg' >
                            <img src='https://quantra.in/wp-content/uploads/2023/01/9962_desktopp_application_1.jpg' className='mb-2' style={{ width: '33.33333333333333%', borderRadius: 2 }} />
                        </a>

                        <a className='galler-image' href='https://quantra.in/wp-content/uploads/2023/01/app-2-15.jpg' >
                            <img src='https://quantra.in/wp-content/uploads/2023/01/app-2-15.jpg' className='mb-2' style={{ width: '33.33333333333333%', borderRadius: 2 }} />
                        </a>

                        <a className='galler-image' href='https://quantra.in/wp-content/uploads/2023/01/9960_desktopp_application_1.jpg' >
                            <img src='https://quantra.in/wp-content/uploads/2023/01/9960_desktopp_application_1.jpg' className='mb-2' style={{ width: '33.33333333333333%', borderRadius: 2 }} />
                        </a>
                        <a className='galler-image' href='https://quantra.in/wp-content/uploads/2023/01/app-1-21.jpg' >
                            <img src='https://quantra.in/wp-content/uploads/2023/01/app-1-21.jpg' className='mb-2' style={{ width: '33.33333333333333%', borderRadius: 2 }} />
                        </a>
                        <a className='galler-image' href='https://quantra.in/wp-content/uploads/2023/01/app-1.jpg' >
                            <img src='https://quantra.in/wp-content/uploads/2023/01/app-1.jpg' className='mb-2' style={{ width: '33.33333333333333%', borderRadius: 2 }} />
                        </a>



                        <a className='galler-image' href='https://quantra.in/wp-content/uploads/2023/01/app-3.jpg' >
                            <img src='https://quantra.in/wp-content/uploads/2023/01/app-3.jpg' className='mb-2' style={{ width: '33.33333333333333%', borderRadius: 2 }} />
                        </a>
                    </LightGallery>
                    <a href='https://quantra.in/product-category/slabs/?attribute=by-colour&child=white' target='_blank'> <button className='car-button' >View More</button></a>
                </div>
            </Fade>
            <Fade>
                <div className='p-5 pt-2 pb-2' style={{ paddingTop: 3, borderRadius: 5, alignItems: 'center', justifyContent: 'center' }} >
                    <Typography style={{ fontSize: '1.6rem', color: 'red', fontStyle: 'italic' }} >Beige</Typography>
                    <LightGallery
                        onBeforeSlide={onBeforeSlide}
                        speed={790}
                        plugins={[lgThumbnail, lgZoom, lgShare, lgRotate]}

                    >
                        <a className='galler-image' href='https://quantra.in/wp-content/uploads/2022/09/app-1-2.jpg' >
                            <img src='https://quantra.in/wp-content/uploads/2022/09/app-1-2.jpg' className='mb-2' style={{ width: '33.33333333333333%', borderRadius: 2 }} />
                        </a>

                        <a className='galler-image' href='https://quantra.in/wp-content/uploads/2022/09/app-3-1.jpg' >
                            <img src='https://quantra.in/wp-content/uploads/2022/09/app-3-1.jpg' className='mb-2' style={{ width: '33.33333333333333%', borderRadius: 2 }} />
                        </a>

                        <a className='galler-image' href='https://quantra.in/wp-content/uploads/2022/09/app-2-4.jpg' >
                            <img src='https://quantra.in/wp-content/uploads/2022/09/app-2-4.jpg' className='mb-2' style={{ width: '33.33333333333333%', borderRadius: 2 }} />
                        </a>

                        <a className='galler-image' href='https://quantra.in/wp-content/uploads/2022/09/app-1-3.jpg' >
                            <img src='https://quantra.in/wp-content/uploads/2022/09/app-1-3.jpg' className='mb-2' style={{ width: '33.33333333333333%', borderRadius: 2 }} />
                        </a>
                        <a className='galler-image' href='https://quantra.in/wp-content/uploads/2022/09/app-2-5.jpg' >
                            <img src='https://quantra.in/wp-content/uploads/2022/09/app-2-5.jpg' className='mb-2' style={{ width: '33.33333333333333%', borderRadius: 2 }} />
                        </a>
                        <a className='galler-image' href='https://quantra.in/wp-content/uploads/2022/09/Bravura-application-3.jpg' >
                            <img src='https://quantra.in/wp-content/uploads/2022/09/Bravura-application-3.jpg' className='mb-2' style={{ width: '33.33333333333333%', borderRadius: 2 }} />
                        </a>


                    </LightGallery>
                    <a href='https://quantra.in/product-category/slabs/?attribute=by-colour&child=beige' target='_blank'> <button className='car-button' >View More</button></a>
                </div>
            </Fade>
            <Fade>
                <div className='p-5 pt-2 pb-2' style={{ paddingTop: 3, borderRadius: 5, alignItems: 'center', justifyContent: 'center' }} >
                    <Typography style={{ fontSize: '1.6rem', color: 'red', fontStyle: 'italic' }} >Gray</Typography>
                    <LightGallery
                        onBeforeSlide={onBeforeSlide}
                        speed={790}
                        plugins={[lgThumbnail, lgZoom, lgShare, lgRotate]}

                    >
                        <a className='galler-image' href='https://quantra.in/wp-content/uploads/2022/09/app-1.jpg' >
                            <img src='https://quantra.in/wp-content/uploads/2022/09/app-1.jpg' className='mb-2' style={{ width: '33.33333333333333%', borderRadius: 2 }} />
                        </a>

                        <a className='galler-image' href='https://quantra.in/wp-content/uploads/2022/09/app-2.jpg' >
                            <img src='https://quantra.in/wp-content/uploads/2022/09/app-2.jpg' className='mb-2' style={{ width: '33.33333333333333%', borderRadius: 2 }} />
                        </a>

                        <a className='galler-image' href='https://quantra.in/wp-content/uploads/2021/06/Alonso-7733-Application.jpg' >
                            <img src='https://quantra.in/wp-content/uploads/2021/06/Alonso-7733-Application.jpg' className='mb-2' style={{ width: '33.33333333333333%', borderRadius: 2 }} />
                        </a>

                       
                        <a className='galler-image' href='https://quantra.in/wp-content/uploads/2022/09/app-1-5.jpg' >
                            <img src='https://quantra.in/wp-content/uploads/2022/09/app-1-5.jpg' className='mb-2' style={{ width: '33.33333333333333%', borderRadius: 2 }} />
                        </a>
                        <a className='galler-image' href='https://quantra.in/wp-content/uploads/2022/09/app-2-7.jpg' >
                            <img src='https://quantra.in/wp-content/uploads/2022/09/app-2-7.jpg' className='mb-2' style={{ width: '33.33333333333333%', borderRadius: 2 }} />
                        </a>


                        <a className='galler-image' href='https://quantra.in/wp-content/uploads/2022/09/app-3-3.jpg' >
                            <img src='https://quantra.in/wp-content/uploads/2022/09/app-3-3.jpg' className='mb-2' style={{ width: '33.33333333333333%', borderRadius: 2 }} />
                        </a>
                        <a className='galler-image' href='https://quantra.in/wp-content/uploads/2022/09/DESKTOP-application-1.jpg' >
                            <img src='https://quantra.in/wp-content/uploads/2022/09/DESKTOP-application-1.jpg' className='mb-2' style={{ width: '33.33333333333333%', borderRadius: 2 }} />
                        </a>


                        <a className='galler-image' href='https://quantra.in/wp-content/uploads/2022/09/DESKTOP-Application-2.jpg' >
                            <img src='https://quantra.in/wp-content/uploads/2022/09/DESKTOP-Application-2.jpg' className='mb-2' style={{ width: '33.33333333333333%', borderRadius: 2 }} />
                        </a>
                        <a className='galler-image' href='https://quantra.in/wp-content/uploads/2022/09/DESKTOP-application-3.jpg' >
                            <img src='https://quantra.in/wp-content/uploads/2022/09/DESKTOP-application-3.jpg' className='mb-2' style={{ width: '33.33333333333333%', borderRadius: 2 }} />
                        </a>
                        <a className='galler-image' href='https://quantra.in/wp-content/uploads/2023/01/app-1-12.jpg' >
                            <img src='https://quantra.in/wp-content/uploads/2023/01/app-1-12.jpg' className='mb-2' style={{ width: '33.33333333333333%', borderRadius: 2 }} />
                        </a>
                        <a className='galler-image' href='https://quantra.in/wp-content/uploads/2023/01/app2.jpg' >
                            <img src='https://quantra.in/wp-content/uploads/2023/01/app2.jpg' className='mb-2' style={{ width: '33.33333333333333%', borderRadius: 2 }} />
                        </a>
                        <a className='galler-image' href='https://quantra.in/wp-content/uploads/2023/01/app-3-7.jpg' >
                            <img src='https://quantra.in/wp-content/uploads/2023/01/app-3-7.jpg' className='mb-2' style={{ width: '33.33333333333333%', borderRadius: 2 }} />
                        </a>
                        <a className='galler-image' href='https://quantra.in/wp-content/uploads/2022/09/DESKTOP-application-1.jpg' >
                            <img src='https://quantra.in/wp-content/uploads/2022/09/DESKTOP-application-1.jpg' className='mb-2' style={{ width: '33.33333333333333%', borderRadius: 2 }} />
                        </a>
                    </LightGallery>
                    <a href='https://quantra.in/product-category/slabs/?attribute=by-colour&child=grey' target='_blank'> <button className='car-button' >View More</button></a>
                </div>
            </Fade>
            <div>

                <Dialog
                    fullScreen={fullScreen}
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="responsive-dialog-title"
                >

                    <DialogContent>
                        <Typography style={{ fontSize: '1.6rem', marginBottom: 10 }} >Choose Image</Typography>
                        <input aria-busy type='file' style={{ width: '16rem', cursor: 'pointer' }} ></input>
                    </DialogContent>
                    <DialogActions>

                        <Button autoFocus>
                            Cancel
                        </Button>
                        <Button autoFocus>
                            Upload
                        </Button>
                    </DialogActions>
                </Dialog>
            </div>

        </div>
    );
}

export default Gallery