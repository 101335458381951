import React from "react";
import './component.css'
import { p } from "@mui/material";

export const About = () => {



    return (
        <div>
            <div style={{ height: '96vh', marginTop: '-5rem', justifyContent: 'center', alignItems: 'center', backgroundColor: 'white' }} className="about-con d-none d-md-flex " >

                <div style={{ borderRadius: '0.3rem', height: '76vh', width: '82%', backgroundColor: 'white', position: 'relative', left: '6rem', backgroundImage: 'url(https://quantra.in/wp-content/uploads/2022/01/JMP-one.jpg)', backgroundSize: 'cover', backgroundPosition: 'center' }} >
                </div>

                <div style={{ width: '70%', backgroundColor: 'white', position: 'relative', right: '6rem', overflowY: 'auto', height: '73vh' }} className="about-shadow p-4" >
                    <p style={{ fontSize: '2rem', color: 'red', marginTop:10 }} >About Us</p>
                    <p style={{ fontSize: '1rem', fontWeight: '500' }} >
                        Quantra is the toughest, most beautiful surface in the world for kitchen and bathroom countertops as well as wall claddings
                    </p>
                    <p style={{ borderBottom: '2px solid gray', width: '6rem', position: 'relative',marginTop:-10, }} ></p>
                    <br />
                    <br />
                    
                    <p style={{ fontSize: '0.9rem', color: 'grey', marginTop: '0.2rem', fontStyle: 'italic', marginBottom: '0.6rem' }} >
                        Made in two export-driven state-of-the art factories using the globally-patented technology of Breton of Italy
                    </p>

                    <p style={{ fontSize: '0.9rem', color: 'grey', marginBottom: '0.4rem' }} >
                        360-degree end-to-end installation
                    </p>
                    <p style={{ fontSize: '0.9rem', color: 'grey', marginBottom: '0.4rem' }} >
                        Zero debris, zero stress
                    </p>
                    <p style={{ fontSize: '0.9rem', color: 'grey', marginBottom: '0.4rem' }} >
                        Choose from over 100 surface designs
                    </p>
                    <p style={{ fontSize: '0.9rem', color: 'grey', marginBottom: '0.4rem' }} >
                        Now available in India
                    </p>
                </div>

            </div>
            <div style={{ marginTop: '-8rem', justifyContent: 'center', alignItems: 'center', backgroundColor: 'white' }} className="about-con d-block d-md-none " >

                <div style={{ borderRadius: '0.3rem', height: '76vh', width: '100%', backgroundColor: 'white', position: 'relative', backgroundImage: 'url(https://quantra.in/wp-content/uploads/2022/01/JMP-one.jpg)', backgroundSize: 'cover', backgroundPosition: 'center' }} >
                </div>

                <div style={{ width: '100%', backgroundColor: 'white', position: 'relative', right: '0rem', top: '-1.6rem', borderEndStartRadius: '2rem', borderTopRightRadius: '2rem', overflowY: 'auto' }} className="about-shadow p-4" >
                    <p style={{ fontSize: '2rem', color: 'red' }} >About Us</p>
                    <p style={{ fontSize: '1rem', fontWeight: '500' }} >
                        Quantra is the toughest, most beautiful surface in the world for kitchen and bathroom countertops as well as wall claddings
                    </p>
                    <p style={{ borderBottom: '2px solid red', width: '7rem', position: 'relative', }} ></p>
                    <p style={{ fontSize: '0.9rem', color: 'grey', marginTop: '1rem', fontStyle: 'italic', marginBottom: '0.6rem' }} >
                        Made in two export-driven state-of-the art factories using the globally-patented technology of Breton of Italy
                    </p>

                    <p style={{ fontSize: '0.9rem', color: 'grey', marginBottom: '0.4rem' }} >
                        360-degree end-to-end installation
                    </p>
                    <p style={{ fontSize: '0.9rem', color: 'grey', marginBottom: '0.4rem' }} >
                        Zero debris, zero stress
                    </p>
                    <p style={{ fontSize: '0.9rem', color: 'grey', marginBottom: '0.4rem' }} >
                        Choose from over 100 surface designs
                    </p>
                    <p style={{ fontSize: '0.9rem', color: 'grey', marginBottom: '0.4rem' }} >
                        Now available in India
                    </p>
                
                </div>

            </div>
        </div>
    )
}