

import '../node_modules/bootstrap/dist/css/bootstrap.css'
import '../node_modules/bootstrap/dist/js/bootstrap'
import './Container/Comoponent/component.css'
import './App.css'
import { Route, BrowserRouter, Routes } from 'react-router-dom'
import { QuantraLanding } from './Container/QuantraLanding.js/QuantraLanding'
import Gallery from './Container/WebScreen/Gallery'
import { AboutPage } from './Container/WebScreen/About'
import { UspsPage } from './Container/WebScreen/Usps'
import { TagManager } from './Container/QuantraLanding.js/TagManager'



function App() {
  return (
    <BrowserRouter>
      <TagManager></TagManager>
      <Routes>
        <Route path='/' element={<QuantraLanding />} />
        <Route path='/gallery' element={<Gallery />} />
        <Route path='/about' element={<AboutPage />} />
        <Route path='/usps' element={<UspsPage />} />
      </Routes>

    </BrowserRouter>
  );
}

export default App;
