import { Typography } from '@mui/material'
import React from 'react'
import { UspsContant } from './UspsContant'

export const UspsHeader = () => {
    return (
        <div>
            <div className='d-none d-md-flex' style={{ backgroundImage: 'url(https://quantra.in/wp-content/uploads/2023/01/app-1-9.jpg)', width: '100%', height: '50vh', display: 'flex', justifyContent: 'center', position: 'relative', alignItems: 'center', }} >
                <UspsContant />
            </div>
            <div className='d-flex d-md-none' style={{ backgroundImage: 'url(https://quantra.in/wp-content/uploads/2023/01/app-1-9.jpg)', width: '100%', height: '75vh', display: 'flex', justifyContent: 'center', position: 'relative', alignItems: 'center', }} >
                <UspsContant />
            </div>
        </div>
    )
}