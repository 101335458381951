import React from 'react'

export const FooterCard = () => {
    return (
        <div>

            <div className='d-none d-lg-flex' style={{ padding: 8, display: 'flex', justifyContent: 'center' }} >
                <div style={{ display: 'flex', justifyContent: 'space-around', alignItems: 'center', padding: 10, width: '70%' }} >
                    <img src='https://quantra.in/wp-content/uploads/2021/07/image.jpg' ></img>
                    <img src='https://quantra.in/wp-content/uploads/2021/07/image-1.jpg' ></img>
                    <img src='https://quantra.in/wp-content/uploads/2021/07/image-2.jpg' ></img>
                    <img src='https://quantra.in/wp-content/uploads/2021/07/DNV-GL-1.jpg' ></img>
                    <img src='https://quantra.in/wp-content/uploads/2021/07/image-3925.jpg' ></img>
                    <img src='https://quantra.in/wp-content/uploads/2021/07/Breton.jpg' ></img>
                </div>
            </div>

            <div className='d-flex d-lg-none' style={{ display: 'flex', justifyContent: 'center' }} >
                <div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }} >
                    
                        <img src='https://quantra.in/wp-content/uploads/2021/07/image.jpg' style={{ margin: 5 }} ></img>
                        <img src='https://quantra.in/wp-content/uploads/2021/07/image-1.jpg' style={{ margin: 5 }} ></img>
                        <img src='https://quantra.in/wp-content/uploads/2021/07/image-2.jpg' style={{ margin: 5 }} ></img>
                    
                    </div>

                    <br />

                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }} >

                        <img src='https://quantra.in/wp-content/uploads/2021/07/DNV-GL-1.jpg' style={{ margin: 5 }} ></img>
                        <img src='https://quantra.in/wp-content/uploads/2021/07/image-3925.jpg' style={{ margin: 5 }} ></img>
                        <img src='https://quantra.in/wp-content/uploads/2021/07/Breton.jpg' style={{ margin: 5 }} ></img>

                    </div>
                </div>
            </div>
        </div>
    )
}