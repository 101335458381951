import React, { useEffect } from 'react'
import { AboutHeader } from '../Comoponent/AboutHeader'
import { AboutStory } from '../Comoponent/AboutStory'
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';

export const AboutPage = () => {
  const navigate = useNavigate()
  const scrollUp = () => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    scrollUp()
  }, [])
  return (
    <div>
     
      <div className='card nav-container' style={{ width: '100%', height: '4.9rem', background: 'white', borderRadius: '0.0003rem', border: 'none', justifyContent: 'center', position: 'fixed', top: '0rem', zIndex: 100 }} >
        <div className='container-fluid card' style={{ borderRadius: '0.0rem', border: 'none', backgroundColor: 'white', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row', width: '100%' }} >
          <div style={{ width: '45%', border: '0.7px solid lightgray' }} ></div>
          <div onClick={() => navigate('/')} style={{ position: 'relative', top: '-0.21rem' }} >
            <p className='v-con-logo typo' style={{ cursor: 'pointer', color: 'white', fontSize: '1.4rem', fontWeight: '800', }} ><img src={require('../../assets/qlogo.png')} style={{ width: '150px', borderRadius: '0.1rem', marginRight: 3, position: 'relative', top: '0.2rem' }} /></p>
          </div>
          <div style={{ width: '45%', border: '0.7px solid lightgray' }} ></div>
        </div>
      </div>
      <div>
        <AboutHeader />
      </div>
      <div>
        <AboutStory />
      </div>
    </div>
  )
} 