import { Typography } from '@mui/material'
import React from 'react'

export const AboutHeader = () => {
    return (
        <div>
            <div style={{ backgroundImage: 'url(https://quantra.in/wp-content/uploads/2023/01/app-1-8.jpg)', width: '100%', height: '100vh', display:'flex', justifyContent:'center', alignItems:'center' }} >
                <p style={{ fontSize: '2.5rem', color: 'white', fontWeight: '600', textShadow: '1px 2px 1px black' }} >About Us</p>
            </div>
        </div>
    )
}