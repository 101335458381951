import { p } from '@mui/material'
import React from 'react'
import CircleIcon from '@mui/icons-material/Circle';

export const AboutStory = () => {

    return (
        <div>
            <div className='d-none d-md-block' style={{ position: 'relative', marginTop: '-18rem', padding: 58, paddingBottom: 30 }} >
                <div style={{ backgroundColor: 'white', padding: 59 }} >

                    <p className='typo' style={{ fontSize: '1.8rem', color: 'gray' }} >WHY QUANTRA</p>
                    <br />
                    <p className='typo' style={{ fontSize: '0.9rem', color: 'grey', marginBottom: '0.7rem', fontStyle: 'italic' }} >
                        <CircleIcon style={{fontSize:'0.6rem', position:'relative', top:'-0.08rem', color:'black'}} /> Quantra is the toughest, most beautiful surface in the world for kitchen and bathroom countertops as well as wall claddings
                    </p>
                    <p className='typo' style={{ fontSize: '0.9rem', color: 'grey', marginBottom: '0.7rem', fontStyle: 'italic' }} >
                    <CircleIcon style={{fontSize:'0.6rem', position:'relative', top:'-0.08rem', color:'black'}} /> Made in two export-driven state-of-the art factories using the globally-patented technology of Breton of Italy
                    </p>
                    <p className='typo' style={{ fontSize: '0.9rem', color: 'grey', marginBottom: '0.7rem', fontStyle: 'italic' }} >
                    <CircleIcon style={{fontSize:'0.6rem', position:'relative', top:'-0.08rem', color:'black'}} /> 360-degree end-to-end installation
                    </p>
                    <p className='typo' style={{ fontSize: '0.9rem', color: 'grey', marginBottom: '0.7rem', fontStyle: 'italic' }} >
                    <CircleIcon style={{fontSize:'0.6rem', position:'relative', top:'-0.08rem', color:'black'}} /> Zero debris, zero stress
                    </p>

                    <p className='typo' style={{ fontSize: '0.9rem', color: 'grey', marginBottom: '0.7rem', fontStyle: 'italic' }} >
                    <CircleIcon style={{fontSize:'0.6rem', position:'relative', top:'-0.08rem', color:'black'}} /> Choose from over 100 surface designs
                    </p>
                    <p className='typo' style={{ fontSize: '0.9rem', color: 'grey', marginBottom: '0.7rem', fontStyle: 'italic' }} >
                    <CircleIcon style={{fontSize:'0.6rem', position:'relative', top:'-0.08rem', color:'black'}} /> Now available in India
                    </p>


                    <br />


                </div>
            </div>

        </div>
    )

}