import { useEffect } from 'react'
import tagManager from 'react-gtm-module'

export const TagManager = () => {
    const tagManagerArgs = {
        gtmId: 'GTM-T9W2RJPB'
    }

    useEffect(() => {
        tagManager.initialize(tagManagerArgs)
    }, [])
}