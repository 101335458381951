import React from 'react'
import styled from 'styled-components';
import SliderComp from './Slider';
import { Zoom } from 'react-awesome-reveal';

const Projects = () => {
    return (
        <Container id='project'>
            <Zoom>
                <h1>Recent <span className="green" style={{color:'red'}}>Projects</span></h1>
                <p style={{ width: '60%' }} >Discover projects made with Quantra. See some of the select projects submitted by professionals like Architects, Brands, Builders & Contractor, Dealers, Designers, or Stone Masons who have worked with Quantra.</p>
            </Zoom>
            <Slide>
                <SliderComp />
            </Slide>
            <br/>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: '1rem' }} >
                <a href='https://quantra.in/projects/' target='_blank' > <button className='car-button1' >More Projects</button></a>
            </div>
        </Container>
    )
}

export default Projects;

const Container = styled.div`
    width: 100%;
    margin: 0 auto;
    padding: 1rem 0;
    text-align: center;
    position: relative;
    @media(max-width: 840px){
        width: 100%;
    }
    h1{
        font-size: 1.9rem;
    }

    p{
        width: 28rem;
        margin: 0 auto;
        padding: 1rem 0;
        font-size: 0.9rem;
        @media(max-width : 500px){
            width: 90%;
        }
    }
    
`

const Slide = styled.div``