import React from 'react'
import './component.css'
import { p } from '@mui/material'
import { Fade } from 'react-awesome-reveal';

export const QProject = () => {
    return (
        <div style={{ paddingTop: '0rem', marginTop: '-2rem' }} >
            <div className='d-block d-lg-none p-2'>
                <p className='typo' style={{ fontSize: '2.7rem', color: 'gray' }} >Quantra Projects</p>
                <p className='typo' style={{ fontSize: '1rem' }} >Discover projects made with Quantra. See some of the select projects submitted by professionals like Architects, Brands, Builders & Contractor, Dealers, Designers, or Stone Masons who have worked with Quantra.</p>
                <p className='typo' style={{ borderBottom: '2px solid red', width: '7rem', position: 'relative', marginTop: '1.5rem' }} ></p>
            </div>
            <div style={{ paddingLeft: '7rem', paddingRight: '7rem' }} className='d-none d-lg-block'>
                <p className='typo' style={{ fontSize: '2.7rem', color: 'gray' }} >Quantra Projects</p>
                <p className='typo' style={{ fontSize: '1.1rem', color: 'black', fontWeight: '600' }} >Discover projects made with Quantra. See some of the select projects submitted by professionals like Architects, Brands, Builders & Contractor, Dealers, Designers, or Stone Masons who have worked with Quantra.</p>
                <p className='typo' style={{ borderBottom: '2px solid gray', width: '7rem', position: 'relative', marginTop: '1.3rem' }} ></p>
            </div>
            <div className='p-1 p-lg-2 p-xl-3 p-xxl-5' >
                <div className='container-fluid p-1 p-lg-2 p-xl-3 p-xxl-5' style={{ marginTop: -60 }}>
                    <div className='row' >
                        <Fade className="col-12 col-md-6 col-lg-4 ps-lg-4 pe-lg-4" >
                            <center >
                                <div className="img-container">
                                    <center>
                                        <div className="img-inner \">
                                            <div className="inner-skew text-shadow" style={{ position: 'relative', top: 32, }} >
                                                <center><img src="https://quantra.in/wp-content/uploads/2022/01/JMP-one-300x169.jpg" style={{ height: '14rem', width: '100%' }} /></center>
                                            </div>
                                        </div>
                                    </center>
                                    <div style={{ padding: '0.7rem', zIndex: 1000 }} >
                                        <div className='text-shadow' style={{ backgroundColor: 'white', position: 'relative', top: '-1rem', padding: 10 }} >
                                            <p style={{ fontSize: '1.32rem', fontWeight: '600', color: 'black' }} className='typo'>
                                                JP Morgan Chase & Co. Hyderabad
                                            </p>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-container" style={{ height: '18rem', overflowY: 'auto' }}>
                                    <p className='typo' style={{ textAlign: 'start', color: 'red', fontSize: 15, fontWeight: 'bold', color: 'black' }} >Gensler, Vestian</p>
                                    <p className='typo' style={{ textAlign: 'start', color: 'gray', fontSize: 14 }} >
                                        The new LEED-certified JP Morgan Chase & Co. in Hyderabad, India is the company’s largest campus in Asia-Pacific. Designed as a flexible workspace, the office features high-tech work environments that focus on...
                                    </p>
                                </div>
                            </center>
                        </Fade>
                        <Fade className="col-12 col-md-6 col-lg-4 col-lg-4 ps-lg-4 pe-lg-4" >
                            <div >
                                <center>
                                    <div className="img-container">
                                        <div className="img-inner">
                                            <div className="inner-skew text-shadow" style={{ position: 'relative', top: 32 }} >
                                                <img src="https://quantra.in/wp-content/uploads/2021/09/banner-300x169.jpg" style={{ height: '14rem', width: '100%' }} />
                                            </div>
                                        </div>
                                        <div style={{ padding: '0.7rem', zIndex: 1000 }} >
                                            <div className='text-shadow' style={{ backgroundColor: 'white', position: 'relative', top: '-1rem', padding: 10 }} >
                                                <p style={{ fontSize: '1.32rem', fontWeight: '600', color: 'black' }} >
                                                    Emerald Maldives Resort & Spa, Fasmendhoo, Maldives
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </center>
                                <div className="text-container" style={{ height: '18rem', overflowY: 'auto' }}>
                                    <p className='typo' style={{ textAlign: 'start', color: 'red', fontSize: 15, fontWeight: 'bold', color: 'black' }} >RQ London</p>
                                    <p className='typo' style={{ textAlign: 'start', color: 'gray', fontSize: 14 }} >
                                        The Emerald Maldives Resort & Spa, one of the world’s most luxurious resorts, combines tropical scenery with modern design, creating atmospheres of natural and informal elegance. Not surprisingly, The Emerald...
                                    </p>
                                </div>
                            </div>
                        </Fade>
                        <Fade className="col-12 col-md-6 col-lg-4 col-lg-4 ps-lg-4 pe-lg-4" >
                            <div >
                                <center>
                                    <div className="img-container">
                                        <div className="img-inner">
                                            <div className="inner-skew text-shadow" style={{ position: 'relative', top: 32 }} >
                                                <img src="https://quantra.in/wp-content/uploads/2019/07/Windshell-Naradhiwas-Condo_Residential_Bangkok_1-300x169.jpg" style={{ height: '14rem', width: '100%' }} />
                                            </div>
                                        </div>
                                        <div style={{ padding: '0.7rem', zIndex: 1000 }} >
                                            <div className='text-shadow' style={{ backgroundColor: 'white', position: 'relative', top: '-1rem', padding: 10 }} >
                                                <p style={{ fontSize: '1.32rem', fontWeight: '600', color: 'black' }} className='typo'>
                                                    Windshell Naradhiwas Condo, Bangkok
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </center>
                                <div className="text-container" style={{ height: '18rem', overflowY: 'auto' }}>
                                    <p className='typo' style={{ textAlign: 'start', color: 'red', fontSize: 15, fontWeight: 'bold', color: 'black' }} >Array, Array</p>
                                    <p className='typo' style={{ textAlign: 'start', color: 'gray', fontSize: 14 }} >
                                        Industrial meets elegance in this Bangkok condo. The concrete wall and warehouse-like stairs sit in harmony with the sleek contemporary cabinets. The island and countertops, constructed with Arnolfini...
                                    </p>
                                </div>
                            </div>
                        </Fade>

                        <center>
                            <a style={{ textDecoration: 'none', display: 'flex', justifyContent: 'center', alignItems: 'center' }} ><p style={{ fontSize: 15, color: 'gray', verticalAlign: 'center', position: 'relative', top: 7.9, fontWeight: '400' }} >For more details on Quantra Quartz please visit the website <a style={{ textDecoration: 'none', color: 'black' }} >www.quantra.in</a></p></a>
                        </center>

                    </div>
                </div>
            </div>
            <br />
        </div>
    )
}