import React, { useEffect } from 'react'
import { UspsHeader } from '../Comoponent/UspsHeader';
import { UspsContant } from '../Comoponent/UspsContant';
import { Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';


export const UspsPage = () => {


  const navigate = useNavigate()

  const scrollUp = () => {
    window.scroll({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    scrollUp()
  }, [])
  return (
    <div>

       
      <div>
        <UspsHeader />
      </div>

    </div>
  )
} 