import { Typography, p } from '@mui/material'
import React from 'react'
import CircleIcon from '@mui/icons-material/Circle';

export const UspsContant = () => {

    return (
        <div>
            <div className='d-none d-md-block' >
                <div style={{ backgroundColor: 'white', padding: 24, width: '53rem' }} >
                    <p className='typo' style={{ fontSize: '1.8rem', color: 'gray' }} >WHY QUANTRA</p>
                    <br />
                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }} >
                        <div>
                            <p className='typo' style={{ fontSize: '0.9rem', color: 'grey', marginBottom: '0.7rem', fontStyle: 'italic' }} >
                                <CircleIcon style={{ fontSize: '0.6rem', position: 'relative', top: '-0.08rem', color: 'black' }} /> Exceptionally hard
                            </p>
                            <p className='typo' style={{ fontSize: '0.9rem', color: 'grey', marginBottom: '0.7rem', fontStyle: 'italic' }} >
                                <CircleIcon style={{ fontSize: '0.6rem', position: 'relative', top: '-0.08rem', color: 'black' }} /> Stain and scratch resistant
                            </p>
                        </div>

                        <div>
                            <p className='typo' style={{ fontSize: '0.9rem', color: 'grey', marginBottom: '0.7rem', fontStyle: 'italic' }} >
                                <CircleIcon style={{ fontSize: '0.6rem', position: 'relative', top: '-0.08rem', color: 'black' }} /> Hygienic and food safe
                            </p>
                            <p className='typo' style={{ fontSize: '0.9rem', color: 'grey', marginBottom: '0.7rem', fontStyle: 'italic' }} >
                                <CircleIcon style={{ fontSize: '0.6rem', position: 'relative', top: '-0.08rem', color: 'black' }} /> Global certifications
                            </p>

                        </div>

                        <div>

                            <p className='typo' style={{ fontSize: '0.9rem', color: 'grey', marginBottom: '0.7rem', fontStyle: 'italic' }} >
                                <CircleIcon style={{ fontSize: '0.6rem', position: 'relative', top: '-0.08rem', color: 'black' }} /> Global certifications
                            </p>
                            <p className='typo' style={{ fontSize: '0.9rem', color: 'grey', marginBottom: '0.7rem', fontStyle: 'italic' }} >
                                <CircleIcon style={{ fontSize: '0.6rem', position: 'relative', top: '-0.08rem', color: 'black' }} /> Sustainably manufactured
                            </p>

                        </div>

                        <div>
                            <p className='typo' style={{ fontSize: '0.9rem', color: 'grey', marginBottom: '0.7rem', fontStyle: 'italic' }} >
                                <CircleIcon style={{ fontSize: '0.6rem', position: 'relative', top: '-0.08rem', color: 'black' }} /> Hygienic and food safe
                            </p>

                            <p className='typo' style={{ fontSize: '0.9rem', color: 'grey', marginBottom: '0.7rem', fontStyle: 'italic' }} >
                                <CircleIcon style={{ fontSize: '0.6rem', position: 'relative', top: '-0.08rem', color: 'black' }} /> No compromises
                            </p>
                        </div>
                    </div>
                    <br />
                </div>
            </div>
            <div className='d-block d-md-none' style={{ position: 'relative', padding: 23, paddingBottom: 30 }} >
                <div style={{ backgroundColor: 'white', padding: 59 }} >

                    <p className='typo' style={{ fontSize: '1.8rem', color: 'gray' }} >WHY QUANTRA</p>
                    <br />
                    <p className='typo' style={{ fontSize: '0.9rem', color: 'grey', marginBottom: '0.7rem', fontStyle: 'italic' }} >
                        <CircleIcon style={{ fontSize: '0.6rem', position: 'relative', top: '-0.08rem', color: 'black' }} /> International technology
                    </p>
                    <p className='typo' style={{ fontSize: '0.9rem', color: 'grey', marginBottom: '0.7rem', fontStyle: 'italic' }} >
                        <CircleIcon style={{ fontSize: '0.6rem', position: 'relative', top: '-0.08rem', color: 'black' }} /> Uses finest raw materials
                    </p>
                    <p className='typo' style={{ fontSize: '0.9rem', color: 'grey', marginBottom: '0.7rem', fontStyle: 'italic' }} >
                        <CircleIcon style={{ fontSize: '0.6rem', position: 'relative', top: '-0.08rem', color: 'black' }} /> Exceptionally hard
                    </p>
                    <p className='typo' style={{ fontSize: '0.9rem', color: 'grey', marginBottom: '0.7rem', fontStyle: 'italic' }} >
                        <CircleIcon style={{ fontSize: '0.6rem', position: 'relative', top: '-0.08rem', color: 'black' }} /> Stain and scratch resistant
                    </p>

                    <p className='typo' style={{ fontSize: '0.9rem', color: 'grey', marginBottom: '0.7rem', fontStyle: 'italic' }} >
                        <CircleIcon style={{ fontSize: '0.6rem', position: 'relative', top: '-0.08rem', color: 'black' }} /> Global certifications
                    </p>
                    <p className='typo' style={{ fontSize: '0.9rem', color: 'grey', marginBottom: '0.7rem', fontStyle: 'italic' }} >
                        <CircleIcon style={{ fontSize: '0.6rem', position: 'relative', top: '-0.08rem', color: 'black' }} /> Sustainably manufactured
                    </p>
                    <p className='typo' style={{ fontSize: '0.9rem', color: 'grey', marginBottom: '0.7rem', fontStyle: 'italic' }} >
                        <CircleIcon style={{ fontSize: '0.6rem', position: 'relative', top: '-0.08rem', color: 'black' }} /> No compromises
                    </p>

                    <br />

                </div>
            </div>
        </div>
    )

}