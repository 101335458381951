import React from "react";
import './card.scss'
import './component.css'
import { p } from "@mui/material";
import { Zoom, Fade } from 'react-awesome-reveal';
import { useState } from "react";


export const CarouselCard = () => {

    const [isSlab, setIsSlab] = useState(true)
    const [isIn, setIsIn] = useState(false)
    const [isSi, setIsSi] = useState(false)
    const [isBa, setIsBa] = useState(false)
    const [isSh, setIsSh] = useState(false)



    return (
        <div>
            <div style={{ position: 'relative', backgroundColor: 'white', zIndex: 99, padding: '56px' }}>

                <Zoom style={{ marginBottom: '1rem' }} >
                    <p style={{ textAlign: 'center', fontSize: '2.6rem', color: 'lightgray', position: 'relative', top: '1rem' }} >Discover our Gorgeous Designs</p>
                    <center>
                        <p style={{ color: 'black', textAlign: 'center', width: '60%', position: 'relative', top: '1rem', fontSize: '0.9rem' }} >Quantra Quartz is produced in two state-of-the-art plants equipped with the latest advanced technology and
                            using the finest raw materials to give you superlative quartz products.</p>
                    </center>
                    <br />


                    <center className="d-none d-md-block" >
                        <div className="mt-3 mt-md-1" style={{ width: '37rem', display: 'flex', justifyContent: 'space-around', alignItems: 'center' }} >
                            <button

                                onClick={() => {
                                    setIsBa(false)
                                    setIsIn(false)
                                    setIsSh(false)
                                    setIsSi(false)
                                    setIsSlab(true)
                                }}

                                style={{ border: 'none', width: '8rem', backgroundColor: 'white', color: isSlab ? 'red' : 'black' }}> {isSlab ? <img style={{ width: '94px', position: 'relative', top: '-1rem', }} src={require('../../assets/salbsred1.png')} /> : <img style={{ width: '94px', position: 'relative', top: '-1rem', }} src={require('../../assets/salbsgry.png')} />}<p style={{ fontSize: 15.8, position: 'relative', top: '0.2rem', marginTop: '-1.1rem', fontWeight: '600' }}>Slab</p></button>
                            <button

                                onClick={() => {
                                    setIsBa(false)
                                    setIsIn(true)
                                    setIsSh(false)
                                    setIsSi(false)
                                    setIsSlab(false)
                                }}

                                style={{ border: 'none', width: '17rem', backgroundColor: 'white', color: isIn ? 'red' : 'black' }}> {isIn ? <img style={{ width: '40px' }} src={require('../../assets/red.png')} /> : <img style={{ width: '40px' }} src={require('../../assets/intgrative3.png')} />} <br /><br /> <p style={{ fontSize: 15.8, fontWeight: '600' }}>Integrated Vanity Sinks</p></button>
                            <button

                                onClick={() => {
                                    setIsBa(false)
                                    setIsIn(false)
                                    setIsSh(false)
                                    setIsSi(true)
                                    setIsSlab(false)
                                }}

                                style={{ border: 'none', width: '8rem', backgroundColor: 'white', color: isSi ? 'red' : 'black' }}> {isSi ? <img style={{ width: '40px' }} src={require('../../assets/tab.png')} /> : <img style={{ width: '40px' }} src={require('../../assets/tabgray.png')} />} <br /><br /> <p style={{ fontSize: 15.8, fontWeight: '600' }}>Sinks</p></button>
                            <button

                                onClick={() => {
                                    setIsBa(true)
                                    setIsIn(false)
                                    setIsSh(false)
                                    setIsSi(false)
                                    setIsSlab(false)
                                }}

                                style={{ border: 'none', width: '8rem', backgroundColor: 'white', color: isBa ? 'red' : 'black' }}> {isBa ? <img style={{ width: '40px' }} src={require('../../assets/basin.png')} /> : <img style={{ width: '40px' }} src={require('../../assets/basingry.png')} />}<br /><br /> <p style={{ fontSize: 15.8, fontWeight: '600' }}>Basins</p></button>
                            <button

                                onClick={() => {
                                    setIsBa(false)
                                    setIsIn(false)
                                    setIsSh(true)
                                    setIsSi(false)
                                    setIsSlab(false)
                                }}

                                style={{ border: 'none', width: '9rem', backgroundColor: 'white', color: isSh ? 'red' : 'black', marginLeft: 13 }}> {isSh ? <img style={{ width: '40px' }} src={require('../../assets/shower.png')} /> : <img style={{ width: '40px' }} src={require('../../assets/shower3.png')} />}<br /><br /><p style={{ fontSize: 15.8, fontWeight: '600' }}>Shower Trays</p></button>
                        </div>
                    </center>

                    <center className="d-block d-md-none" >
                        <div className="mt-3 mt-md-1" style={{ width: '19rem', display: 'flex', justifyContent: 'space-around', alignItems: 'center' }} >
                            <button

                                onClick={() => {
                                    setIsBa(false)
                                    setIsIn(false)
                                    setIsSh(false)
                                    setIsSi(false)
                                    setIsSlab(true)
                                }}

                                style={{ border: 'none', width: '4rem', backgroundColor: 'white', color: isSlab ? 'red' : 'black' }}> {isSlab ? <img style={{ width: '45px', position: 'relative', top: '-1rem', }} src={require('../../assets/salbsred1.png')} /> : <img style={{ width: '45px', position: 'relative', top: '-1rem', }} src={require('../../assets/salbsgry.png')} />}<p style={{ fontSize: 10, position: 'relative', top: '0.967rem', marginTop: '-1.2rem', fontWeight: '600' }}>Slab</p></button>
                            <button

                                onClick={() => {
                                    setIsBa(false)
                                    setIsIn(true)
                                    setIsSh(false)
                                    setIsSi(false)
                                    setIsSlab(false)
                                }}

                                style={{ border: 'none', width: '8rem', backgroundColor: 'white', color: isIn ? 'red' : 'black' }}> {isIn ? <img style={{ width: '19px' }} src={require('../../assets/red.png')} /> : <img style={{ width: '19px' }} src={require('../../assets/intgrative3.png')} />} <br /><br /> <p style={{ fontSize: 10, fontWeight: '600' }}>Integrated Vanity Sinks</p></button>
                            <button

                                onClick={() => {
                                    setIsBa(false)
                                    setIsIn(false)
                                    setIsSh(false)
                                    setIsSi(true)
                                    setIsSlab(false)
                                }}

                                style={{ border: 'none', width: '4rem', backgroundColor: 'white', color: isSi ? 'red' : 'black' }}> {isSi ? <img style={{ width: '19px' }} src={require('../../assets/tab.png')} /> : <img style={{ width: '19px' }} src={require('../../assets/tabgray.png')} />} <br /><br /> <p style={{ fontSize: 10, fontWeight: '600' }}>Sinks</p></button>
                            <button

                                onClick={() => {
                                    setIsBa(true)
                                    setIsIn(false)
                                    setIsSh(false)
                                    setIsSi(false)
                                    setIsSlab(false)
                                }}

                                style={{ border: 'none', width: '4rem', backgroundColor: 'white', color: isBa ? 'red' : 'black' }}> {isBa ? <img style={{ width: '19px' }} src={require('../../assets/basin.png')} /> : <img style={{ width: '19px' }} src={require('../../assets/basingry.png')} />}<br /><br /> <p style={{ fontSize: 10, fontWeight: '600' }}>Basins</p></button>
                            <button

                                onClick={() => {
                                    setIsBa(false)
                                    setIsIn(false)
                                    setIsSh(true)
                                    setIsSi(false)
                                    setIsSlab(false)
                                }}

                                style={{ border: 'none', width: '5rem', backgroundColor: 'white', color: isSh ? 'red' : 'black', marginLeft: 13 }}> {isSh ? <img style={{ width: '19px' }} src={require('../../assets/shower.png')} /> : <img style={{ width: '19px' }} src={require('../../assets/shower3.png')} />}<br /><br /><p style={{ fontSize: 10, fontWeight: '600' }}>Shower Trays</p></button>
                        </div>
                    </center>

                </Zoom>

                <Fade duration={2500} >

                    {
                        isSlab && (
                            <div>
                                <div className="container-fluid" >

                                    <div className="row gy-4" >
                                        <figure className="col col-12 col-md-6 col-xl-4 mx-auto" >

                                            <div style={{ width: '100%', position: 'relative' }} >

                                                <img src={require('../../assets/SLAB/Arctic-Breeze-7172-Application-1.jpg')} style={{ width: '100%',height:'15rem' }} alt="" />
                                                <figcaption className="figc" >
                                                    <div style={{ height: '8rem' }} >
                                                        <p className="p11" style={{ lineHeight: '1.6rem' }}>
                                                            Evokes a gentle breeze meandering across the arctic sea ice, dotted with wispy clouds.
                                                        </p>
                                                    </div>

                                                </figcaption>
                                            </div>
                                        </figure>
                                        <figure className="col col-12 col-md-6 col-xl-4 mx-auto">

                                            <div style={{ width: '100%', position: 'relative' }} >
                                                <img src={require('../../assets/SLAB/Calacatta-Michelangelo-9000-Application-1.jpg')} style={{ width: '100%',height:'15rem' }} alt="" />
                                                <figcaption className="figc" >
                                                    <div style={{ height: '8rem' }} >
                                                        <p className="p11" style={{ lineHeight: '1.6rem' }}>
                                                            With bold, wide-veining, and utmost realism it's a designer’s dream

                                                        </p>
                                                    </div>

                                                </figcaption>
                                            </div>
                                        </figure>
                                        <figure className="col col-12 col-md-6 col-xl-4 mx-auto">

                                            <div style={{ width: '100%', position: 'relative' }} >
                                                <img src={require('../../assets/SLAB/FontainBleu-9986_RoomScene_2 (1).jpg')} style={{ width: '100%',height:'15rem' }} alt="" />
                                                <figcaption className="figc" >
                                                    <div style={{ height: '8rem' }} >
                                                        <p className="p11" style={{ lineHeight: '1.6rem' }}>
                                                            Reminds us of  the great chateau and the French aristocracy


                                                        </p>
                                                    </div>

                                                </figcaption>
                                            </div>
                                        </figure>
                                    </div>
                                </div>
                            </div>
                        )
                    }

                    {
                        isIn && (
                            <div className="container-fluid" >
                                <div className="row gy-4" >

                                    <figure className="col col-12 col-md-6 col-xl-4 mx-auto ">

                                        <div style={{ position: 'relative', width: '100%' }} >
                                            <img src={require('../../assets/VANITY/LILAZ_1.jpg')} style={{ width: '100%' }} alt="" />
                                            <figcaption className="figc" style={{ marginTop: 25, marginBottom: 0 }} >

                                                <div style={{ height: '8rem' }} >
                                                    <p className="p11" style={{ lineHeight: '1.6rem' }}>
                                                        Lilaz symbolizes the grand classical with elegant understatement
                                                    </p>
                                                </div>

                                            </figcaption>
                                        </div>
                                    </figure>

                                    <figure className="col col-12 col-md-6 col-xl-4 mx-auto ">

                                        <div style={{ position: 'relative', width: '100%' }} >
                                            <img src={require('../../assets/VANITY/MOLINA_1.jpg')} style={{ width: '100%' }} alt="" />
                                            <figcaption className="figc" >

                                                <div style={{ height: '8rem' }} >
                                                    <p className="p11" style={{ lineHeight: '1.6rem' }}>
                                                        Molina is a star with its strong lines and commanding presence
                                                    </p>
                                                </div>

                                            </figcaption>
                                        </div>
                                    </figure>

                                    <figure className="col col-12 col-md-6 col-xl-4 mx-auto ">

                                        <div style={{ position: 'relative', width: '100%' }} >
                                            <img src={require('../../assets/VANITY/VARONE_2.jpg')} style={{ width: '100%' }} alt="" />
                                            <figcaption className="figc" >

                                                <div style={{ height: '8rem' }} >
                                                    <p className="p11" style={{ lineHeight: '1.6rem' }}>
                                                        Varone represents yet another piece of great choreography by Quantra
                                                    </p>
                                                </div>

                                            </figcaption>
                                        </div>
                                    </figure>
                                </div>
                            </div>
                        )
                    }

                    {
                        isSi && (
                            <div className="container-fluid" >
                                <div className='row gy-4' >

                                    <figure className="col col-12 col-md-6 col-xl-4 mx-auto " >

                                        <div style={{ position: 'relative', width: '100%' }} >
                                            <img src={require('../../assets/SINK/BRACCIANO-QS2106-1.jpg')} style={{ width: '100%' }} alt="" />
                                            <figcaption className="figc" >

                                                <div style={{ height: '8rem' }} >
                                                    <p className="p11" style={{ lineHeight: '1.6rem' }}>

                                                        A sink that definitely passes for vintage and contemporary



                                                    </p>
                                                </div>

                                            </figcaption>
                                        </div>
                                    </figure>

                                    <figure className="col col-12 col-md-6 col-xl-4 mx-auto " >

                                        <div style={{ position: 'relative', width: '100%' }} >
                                            <img src={require('../../assets/SINK/COMO-QS2101.jpg')} style={{ width: '100%' }} alt="" />
                                            <figcaption className="figc" >

                                                <div style={{ height: '8rem' }} >
                                                    <p className="p11" style={{ lineHeight: '1.6rem' }}>


                                                        Exquisitely seemingly sculpted piece finished to perfection




                                                    </p>
                                                </div>

                                            </figcaption>
                                        </div>
                                    </figure>

                                    <figure className="col col-12 col-md-6 col-xl-4 mx-auto " >




                                        <div style={{ position: 'relative', width: '100%' }} >
                                            <img src={require('../../assets/SINK/ISEO-QS2104-1.jpg')} style={{ width: '100%' }} alt="" />
                                            <figcaption className="figc" >

                                                <div style={{ height: '8rem' }} >
                                                    <p className="p11" style={{ lineHeight: '1.6rem' }}>
                                                        A perfect circle that complements the most contemporary bathrooms
                                                    </p>
                                                </div>

                                            </figcaption>
                                        </div>
                                    </figure>
                                </div>
                            </div>
                        )
                    }

                    {
                        isBa && (
                            <div className="container-fluid" >
                                <div className="row gy-4" >

                                    <figure className="col col-12 col-md-6 col-xl-4 mx-auto ">

                                        <div style={{ position: 'relative', width: '100%' }} >
                                            <img src={require('../../assets/BASIN/Bellagio-QB2101_Concrete_Grey_4509S.jpg')} style={{ width: '100%' }} alt="" />
                                            <figcaption className="figc" >

                                                <div style={{ height: '8rem' }} >
                                                    <p className="p11" style={{ lineHeight: '1.6rem' }}>

                                                        Bellagio conveys a visual perception of lightness in the bathroom

                                                    </p>
                                                </div>

                                            </figcaption>
                                        </div>
                                    </figure>
                                    <figure className="col col-12 col-md-6 col-xl-4 mx-auto " >

                                        <div style={{ position: 'relative', width: '100%' }} >
                                            <img src={require('../../assets/BASIN/Bellano-QB2102_Concrete Grey_Washbasin.jpg')} style={{ width: '100%' }} alt="" />
                                            <figcaption className="figc" >

                                                <div style={{ height: '8rem' }} >
                                                    <p className="p11" style={{ lineHeight: '1.6rem' }}>

                                                        Sophistication redefined to give a sense of opulence
                                                    </p>
                                                </div>

                                            </figcaption>
                                        </div>
                                    </figure>
                                    <figure className="col col-12 col-md-6 col-xl-4 mx-auto " >

                                        <div style={{ position: 'relative', width: '100%' }} >
                                            <img src={require('../../assets/BASIN/Nesso-QB2110_Mystique_Alpine_7192C.jpg')} style={{ width: '100%' }} alt="" />
                                            <figcaption className="figc" >

                                                <div style={{ height: '8rem' }} >
                                                    <p className="p11" style={{ lineHeight: '1.6rem' }}>

                                                        Crafted using clean geometric lines with the strongest utilitarian properties
                                                    </p>
                                                </div>

                                            </figcaption>
                                        </div>
                                    </figure>

                                </div>
                            </div>
                        )
                    }

                    {
                        isSh && (
                            <div className="container-fluid" >
                                <div className="row gy-4" >
                                    <figure className="col col-12 col-md-6 col-xl-4 mx-auto " >

                                        <div style={{ position: 'relative', width: '100%' }} >
                                            <img src={require('../../assets/SHOWER TRAY/BURANO-QT2109_2_v.1.jpg')} style={{ width: '100%' }} alt="" />
                                            <figcaption className="figc" >

                                                <div style={{ height: '8rem' }} >
                                                    <p className="p11" style={{ lineHeight: '1.6rem' }}>

                                                        Stylish, contemporary, and comfortable for a pleasurable shower


                                                    </p>
                                                </div>

                                            </figcaption>
                                        </div>
                                    </figure>
                                    <figure className="col col-12 col-md-6 col-xl-4 mx-auto ">

                                        <div style={{ position: 'relative', width: '100%' }} >
                                            <img src={require('../../assets/SHOWER TRAY/CAPRI-QT2101_new_carrara_2_2K.jpg')} style={{ width: '100%' }} alt="" />
                                            <figcaption className="figc" >

                                                <div style={{ height: '8rem' }} >
                                                    <p className="p11" style={{ lineHeight: '1.6rem' }}>

                                                        Incredibly stylish with a modern look and feel

                                                    </p>
                                                </div>

                                            </figcaption>
                                        </div>
                                    </figure>
                                    <figure className="col col-12 col-md-6 col-xl-4 mx-auto ">

                                        <div style={{ position: 'relative', width: '100%' }} >
                                            <img src={require('../../assets/SHOWER TRAY/SICILY-QT2107_new_carrara_2_2K.jpg')} style={{ width: '100%' }} alt="" />
                                            <figcaption className="figc" >

                                                <div style={{ height: '8rem' }} >
                                                    <p className="p11" style={{ lineHeight: '1.6rem' }}>

                                                        Enhance the look of your shower area with plentiful space



                                                    </p>
                                                </div>

                                            </figcaption>
                                        </div>
                                    </figure>

                                </div>
                            </div>
                        )
                    }

                </Fade>
            </div>
        </div>
    )
} 