import React, { useState } from "react";
import { FaFacebook, FaInstagram, FaMapMarkerAlt, FaTwitter, FaYoutube } from "react-icons/fa";
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
import Row from 'react-bootstrap/Row';
import axios from "axios";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import ReCAPTCHA from "react-google-recaptcha";
import { TramRounded } from "@mui/icons-material";
import SimpleBackdrop from "../BackDrop/BackDrop";
import { Typography } from "@mui/material";


const Footer = () => {

    const navigate = useNavigate()

    const [name, setName] = useState('')
    const [city, setMobile] = useState('')
    const [email, setEmail] = useState('')
    const [country, setCountry] = useState('')
    const [message, setMessage] = useState('White')


    const [isSubmiting, setIsSubmiting] = useState(false)

    const contact = () => {
        setIsSubmiting(TramRounded)
        axios.post('https://buildfastapi.emedha.in/account-setting/api/v1/add-contact', {
            name,
            email,
            message,
            country,
            city
        }).then((res) => {
            if (res.data.status === 200) {
                setIsSubmiting(false)
                emailSend(name, email, message, country, city)
                window.open('https://quantra.in/thank-you/', '_blank');
            }
        })
    }


    const [Validated, setValidated] = useState(false);

    const handleSubmitGst = (event) => {
        event.preventDefault()
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            isNotValid ? setIsNotValid(true) : contact()
        }

        isNotValid ? setIsNotValid(true) : setIsNotValid(false)
        setValidated(true);

    };

    const emailSend = (name, email, message, country, city) => {
        axios.post('https://buildfastapi.emedha.in/account-setting/api/v1/send-email', {
            name, email, message, country, mobile: city
        }).then((res) => {
            if (res.data.status === 200) {
                return
            }
        })
    }

    const [isNotValid, setIsNotValid] = useState(false)

    const mobileValidation = (e) => {
        console.log(e.target.value.length === 10)
        if (e.target.value.length !== 10) {
            setIsNotValid(true)
        } else {
            setMobile(e.target.value)
            setIsNotValid(false)
            return
        }

    }


    return (
        <div style={{ width: '100%', backgroundColor: 'black', marginTop: 37 }} >
            <div className="container-fluid p-1 p-lg-5" >
                <div className="row p-3 pt-5 pb-5 p-lg-4 gy-3" >
                    <div className="col-12 col-md-5 col-xl-4 mx-auto" >
                        <p style={{ fontSize: 24, fontWeight: '900', color: 'white' }} >Contact Us</p>
                        <div style={{ width: '4rem', height: '0.18rem', backgroundColor: 'white', position: 'relative', top: '-1.3rem' }} ></div>
                        <img src={require('../../assets/qlogo.png')} style={{ width: '120px' }} />
                        <p className="mt-3" >
                            <div className="d-flex" >
                                <FaMapMarkerAlt style={{ color: 'red', fontSize: 28, position: 'relative', top: '1rem' }} />
                                <p style={{ marginTop: 17, fontSize: 16.5, fontWeight: '600', marginLeft: 5, color: 'white' }} >
                                    105, Level 1, Surya Towers,
                                    S.P. Road, Secunderabad 500 003,
                                    Telangana, INDIA
                                </p>

                            </div>
                            <p style={{ marginTop: 0, fontSize: 16.5, fontWeight: '600', marginLeft: 5, color: 'white' }} ><LocalPhoneIcon style={{ fontSize: 19, color: 'red', position: 'relative', top: '-0.1rem' }} /> +91 40 2784 2182</p>
                            <p style={{ marginTop: -6, fontSize: 16.5, fontWeight: '600', marginLeft: 5, color: 'white' }} ><MailOutlineIcon style={{ fontSize: 19, color: 'red', position: 'relative', top: '-0.1rem' }} /> contact@quantra.in</p>
                        </p>
                    </div>
                    <div className="col-12 col-md-5 col-xl-4 mx-auto" >
                        <p style={{ fontSize: 24, fontWeight: '900', color: 'white' }} >Fallow  Us</p>
                        <div style={{ width: '4rem', height: '0.18rem', backgroundColor: 'white', position: 'relative', top: '-1.3rem' }} ></div>
                        <div className="icons">
                            <div style={{ width: '3rem', height: '3rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '0.2rem', border: '1.2px solid white' }} >
                                <a style={{ color: 'white' }} href="https://www.facebook.com/quantrasurfaces" target="_blank" ><FaFacebook className="icon" /></a>
                            </div>
                            <div style={{ width: '3rem', height: '3rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '0.2rem', border: '1.2px solid white' }} >
                                <a style={{ color: 'white' }} href="https://www.instagram.com/quantraquartz/" target="_blank" ><FaInstagram className="icon" /></a>
                            </div>
                            <div style={{ width: '3rem', height: '3rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '0.2rem', border: '1.2px solid white' }} >
                                <a style={{ color: 'white' }} href="https://twitter.com/Quantrasurfaces" target="_blank" ><FaTwitter className="icon" /></a>
                            </div>
                            <div style={{ width: '3rem', height: '3rem', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '0.2rem', border: '1.2px solid white' }} >
                                <a style={{ color: 'white' }} href="https://www.youtube.com/channel/UCBRtgUfGzQj3qbVWKG0HbAA" target="_blank" ><FaYoutube className="icon" /></a>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-5 col-xl-4 mx-auto" >
                        <p style={{ fontSize: 24, fontWeight: '900', color: 'white' }} >Send Email</p>
                        <div style={{ width: '4rem', height: '0.18rem', backgroundColor: 'white', position: 'relative', top: '-1.3rem' }} ></div>
                        <Form noValidate validated={Validated} onSubmit={handleSubmitGst} >
                            <Row className="mb-3">
                                <Form.Group as={Col} md="12" controlId="validationCustom01">

                                    <Form.Control
                                        required

                                        type="text"
                                        placeholder="Full Name*"
                                        style={{ padding: '0.8rem', borderRadius: '0.1rem' }}
                                        onChange={(e) => setName(e.target.value)}
                                        maxLength={25}
                                        minLength={3}

                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please Enter Valid Name
                                    </Form.Control.Feedback>
                                    <br />
                                </Form.Group>
                                <Form.Group as={Col} md="12" controlId="validationCustom01">

                                    <Form.Control
                                        required

                                        type="email"
                                        placeholder="Enter your Email*"
                                        style={{ padding: '0.8rem', borderRadius: '0.1rem' }}
                                        onChange={(e) => setEmail(e.target.value)}


                                    />
                                    <Form.Control.Feedback type="invalid">
                                        Please Enter Valid Email
                                    </Form.Control.Feedback>
                                    <br />
                                </Form.Group>
                                <Form.Group as={Col} md="12" controlId="validationCustom01">

                                    <Form.Control
                                        required
                                        type="number"
                                        placeholder="Mobile*"
                                        style={{ padding: '0.8rem', borderRadius: '0.1rem' }}
                                        onChange={mobileValidation}

                                    />
                                    {
                                        isNotValid && (
                                            <Typography style={{ color: 'red', fontSize: '0.9rem' }} >
                                                Mobile Number should be 10 digits
                                            </Typography>
                                        )
                                    }
                                    <Form.Control.Feedback type="invalid">
                                        Please Enter Valid mobile Number
                                    </Form.Control.Feedback>
                                    <br />
                                </Form.Group>

                                <Form.Group as={Col} md="12" controlId="validationCustom01">

                                    <select
                                        required
                                        type="text"

                                        placeholder="Message *"
                                        style={{ padding: '0.8rem', borderRadius: '0.1rem', marginBottom: 5, width: '100%', border: '1px solid lightgray' }}
                                        onChange={(e) => setMessage(e.target.value)}
                                    >
                                        <option selected value="" disabled >Requirement*</option>
                                        <option>White</option>
                                        <option>Beige</option>
                                        <option>Grey</option>
                                    </select>

                                    <Form.Control.Feedback type="invalid">
                                        Please Enter Valid message
                                    </Form.Control.Feedback>
                                    <br />
                                </Form.Group>
                            </Row>
                            <div style={{ width: '100%' }} >
                                <ReCAPTCHA
                                    sitekey="6Lf8CtgnAAAAABL5f01ssnLvpVAFyLd4cp0ZX9t9"
                                />
                            </div>
                            <button type="submit" style={{ width: '100%' }} className='car-button' >Submit</button>
                        </Form>
                    </div>
                </div>
            </div>
            {
                isSubmiting && (
                    <SimpleBackdrop></SimpleBackdrop>
                )
            }
        </div>
    );
};

export default Footer;
